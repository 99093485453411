<template>
  <div class="culture">
    <div v-if=" fullWidth < 767">
      <Banner
        :bannerimg="staticUrl+(topBanner['cover_mobile_' + $i18n.locale]?topBanner['cover_mobile_' + $i18n.locale]:topBanner.cover_mobile)"
      >
      <p v-html="(topBanner['description_mobile_' + $i18n.locale]?topBanner['description_mobile_' + $i18n.locale]:topBanner.description_mobile) == ''? (topBanner['description_' + $i18n.locale]?topBanner['description_' + $i18n.locale]:topBanner.description) : (topBanner['description_mobile_' + $i18n.locale]?topBanner['description_mobile_' + $i18n.locale]:topBanner.description_mobile)"></p>
      </Banner>
    </div>
    <div v-else>
      <Banner
        :bannerimg="staticUrl+(topBanner['cover_' + $i18n.locale]?topBanner['cover_' + $i18n.locale]:topBanner.cover)" 
      >
      <p v-html="(topBanner['description_' + $i18n.locale]?topBanner['description_' + $i18n.locale]:topBanner.description)"></p>
      </Banner>
    </div>
    <!-- swiper -->
    <div v-if=" fullWidth < 767">
      <swiper class="mb-swiper" :options="swiperOption" ref="mySwiper">
        <swiper-slide class="swiper-slide" v-for="(item,index) in swiperList" :key="index">
          <a :href ="item.url">
            <img v-if=" fullWidth < 767" style="width:100%;height:100%;" :src="staticUrl+(item['cover_mobile_' + $i18n.locale]?item['cover_mobile_' + $i18n.locale]:item.cover_mobile)"/>
            <img v-else style="width:100%;height:100%;" :src="staticUrl+(item['cover_' + $i18n.locale]?item['cover_' + $i18n.locale]:item.cover)"/>
            <div v-html="item['description_mobile_' + $i18n.locale]?item['description_mobile_' + $i18n.locale]:item['description_' + $i18n.locale]" id="cultureCon">
            </div>
          </a> 
        </swiper-slide>
        <!-- Optional controls -->
        <div class="swiper-pagination"  slot="pagination"></div>   <!-- 分页 -->
      </swiper>
    </div>
    <div v-else class="swiper-cont">
      <el-carousel @change="((pre, next,index) => {changeindex(pre, next,index)})" ref="carousel" height="780px" indicator-position="none" :interval="5000">
        <el-carousel-item v-for="item in swiperList" name="index" :key="item['cover_' + $i18n.locale]?item['cover_' + $i18n.locale]:item.cover">
          <a :href ="item.url">
            <img v-if=" fullWidth < 767" style="width:100%;height:100%;" :src="staticUrl+(item['cover_mobile_' + $i18n.locale]?item['cover_mobile_' + $i18n.locale]:item.cover_mobile)"/>
            <img v-else style="object-fit:cover;width:100%;height:100%;" :src="staticUrl+(item['cover_' + $i18n.locale]?item['cover_' + $i18n.locale]:item.cover)"/>
            <div v-html="item['description_' + $i18n.locale]" id="cultureCon">
            </div>
          </a> 
        </el-carousel-item>
      </el-carousel>
      <div class="btn">
        <p><span @click="change('prev')"><i class="el-icon-arrow-left"></i></span></p>
        <p><span>{{imgcount+1}}/{{swiperList.length}}</span></p>
        <p><span class="nextbtn" @click="change('next')"><i class="el-icon-arrow-right"></i></span></p>
      </div>
    </div>
  </div>

  
</template>

<script>
import Banner from '../../components/Banner'
import request from "../../api/request"
export default {
  name: "culture",
  components:{
    Banner,
  },
  data() {
    return {
        topBanner: {},
      imgcount:0,
      staticUrl: request.staticUrl,
      swiperList: [],
      fullWidth: 0,
      swiperOption: { 
        //是一个组件自有属性，如果notNextTick设置为true，组件则不会通过NextTick来实例化swiper，也就意味着你可以在第一时间获取到swiper对象，假如你需要刚加载遍使用获取swiper对象来做什么事，那么这个属性一定要是true 
        notNextTick: true, 
        pagination: '.swiper-pagination', 
        slidesPerView: 'auto', 
        centeredSlides: true, 
        paginationClickable: true, 
        autoplay: 3000,
        spaceBetween: 30, 
        onSlideChangeEnd: swiper => { 
        },
        onSlideChangeStart: swiper => { 
        }  
      },
    };
  },
  mounted(){
    this.banners()
    this.handleResize()
  },
  methods: {
    changeindex (pre, next, index) {
      this.imgcount = pre;
    },
    change(type) {
      this.$refs['carousel'][type]()
    },
    banners(){
      let _this = this
      request.get('/banners', {
        banner_type__in:'1'
        }).then(function (res) {
        if (res.status == 200) {
          _this.swiperList = res.data.results
        }
      })
      request.get('/banners', {
        banner_type__in:'5'
        }).then(function (res) {
        if (res.status == 200) {
            _this.topBanner = res.data.results[0]
        }
      })
    },
    handleResize (event) {
      this.fullWidth = document.documentElement.clientWidth;
    },
  },
  created(){
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.handleResize)
  },
};
</script>


<style>
.culture{
  margin-bottom: 100px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}
.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}
.el-carousel__arrow{
  background-color:transparent !important;
  border: 1px solid #fff;
  height: 50px;
  width: 50px;
  
}
.el-carousel__arrow:hover{
  background-color:rgba(180, 180, 180, 0.5) !important;
}
.culture .btn{
  text-align: center;
  width: 100%;
  margin-top:20px ;
}
.culture .btn p{
  color: #A9A9A9;
  width: 80px;
  display: inline-block;
  text-align: center;
}
.culture .btn span:hover{
  background-color: #000;
  color: #fff;
  padding: 5px;
}
.culture .btn .nextbtn:nth-child(1){
  background-color: #000;
  color: #fff;
  padding: 5px;
}
@media screen and (max-width: 768px){
  .culture{
    margin-bottom: 20px;
  }
}
.culture .swiper-pagination-bullet-active{
  background: #fff !important;
}
#cultureCon{
  position: absolute;
  font-size: 20px;
  bottom: 20%;
  left: 50%; 
  text-align: center;
  left: 50%;
  transform: translate(-50%, -50%); 
  text-align: center;
  color: #fff;
  line-height: 1.4;
  width: 100%;
}
</style>
